const ShowMessageCountMixin = {
  props: {
    messageCount: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
export default ShowMessageCountMixin;
