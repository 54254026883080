'use strict';

import Vue from 'vue';
import state from './config.state';
import { EventBus, Events } from '@/services/EventBus';

let setHtmlLanguageAttribute = function (lang) {
  document.getElementsByTagName('html')[0].setAttribute('lang', lang.substr(0, 2));
};

const actions = {
  changeLanguage (context, lang) {
    Vue.config.language = lang;
    context.commit('SET_LANGUAGE', lang);
    context.dispatch('forceUpdateStaticData');
  },
};

const mutations = {
  SET_LANGUAGE (state, lang) {
    state.language = lang;
    setHtmlLanguageAttribute(lang);
    EventBus.$emit(Events.FORCE_UPDATE);
  },
};

const getters = {
  activeLanguage: function (state) {
    return state.language;
  },
};

setHtmlLanguageAttribute(state.language);

export default {
  state,
  getters,
  actions,
  mutations,
};
