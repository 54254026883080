export const RECORD_TYPE = {
  APPLICANT: 'applicant',
  APPLICANT_MANAGEMENT: 'applicant_management',
  COMPANY: 'company',
  // Bookmarks of applicants (=> can bookmark companies)
  APPLICANT_BOOKMARK: 'applicant_bookmark',
  // Bookmarks of companies (=> can bookmark applicants)
  COMPANY_BOOKMARK: 'company_bookmark',
  // Search of applicants (=> can search companies)
  SEARCH_FOR_COMPANIES: 'search_for_companies',
  // Search of companies (=> can search applicants)
  SEARCH_FOR_APPLICANTS: 'search_for_applicants',
  PAGE: 'page',
  BOOKMARK_LIST_DEFAULT: 'default',
};
