'use strict';

const AUTH_STATE = {
  token: {
    token: '',
    security: '',
  },
};

export default AUTH_STATE;
