<template>
  <!-- Right aligned nav items -->
  <b-navbar-nav class="main-nav">
    <!--
    <b-nav-item :to="{name: 'studyprograms'}">
      <font-awesome-icon icon="question"/>
      {{ $t('Studiengänge') }}
    </b-nav-item>
    -->
    <b-nav-item :to="{name: 'companysearch'}" v-if="enableSearchForCompanies">
      <font-awesome-icon icon="building"/>
      {{ $t('components.partials.header.ba_navigation.companysearch') }}
    </b-nav-item>
    <b-nav-item :to="{name: 'register'}" v-if="!isAuthenticated">
      <font-awesome-icon icon="user"/>
      {{ $t('components.partials.header.ba_navigation.register') }}
    </b-nav-item>
    <b-nav-item :to="{name: 'profile'}" v-else-if="isApplicantAccount">
      <font-awesome-icon icon="user"/>
      {{ $t('components.partials.header.ba_navigation.profile') }}
    </b-nav-item>
    <b-nav-item :to="{name: 'applicantsearch'}" v-else-if="isCompanyAccount">
      <font-awesome-icon icon="user"/>
      {{ $t('components.partials.header.ba_navigation.applicantsearch') }}
    </b-nav-item>
    <b-nav-item :to="{name: 'studypreparation'}">
      <font-awesome-icon icon="book"/>
      {{ $t('components.partials.header.ba_navigation.studypreparation') }}
    </b-nav-item>
    <b-nav-item :href="mathTestUrl" v-if="mathTestUrl">
      <font-awesome-icon icon="calculator"/>
      {{ $t('components.partials.header.ba_navigation.mathx3') }}
    </b-nav-item>
    <b-nav-item :to="{name: 'contact'}">
      <font-awesome-icon icon="envelope"/>
      {{ $t('components.partials.header.ba_navigation.contact') }}
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Config from '@/services/config';

  export default {
    computed: {
      ...mapGetters(['isAuthenticated', 'isApplicantAccount', 'isCompanyAccount']),
      mathTestUrl: function () {
        if (Config.mathTestUrl) {
          return Config.mathTestUrl;
        }
        return null;
      },
      enableSearchForCompanies: function () {
        return !!Config.enableSearchForCompanies;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .main-nav {
    position: relative;
    z-index: 11;
    //padding-top: 18px;
    padding-bottom: 18px;
    .nav-item {
      width: 100%;
      position: relative;
      font-size: 1rem;
      font-weight: $ba-font-weight-medium;
      line-height: normal;
      letter-spacing: 1.41px;
      text-transform: uppercase;
      .nav-link {
        display: block;
        padding: 18px 0 18px 0;
        transition: color .2s linear;
        &:hover,
        &:focus {
          color: $primary;
        }
        svg {
          display: inline-block;
          margin-right: 14px;
        }
      }
      .router-link-active {
        color: $primary;
      }

      @include media-breakpoint-up(lg) {
        padding-right: 9px;
        padding-left: 9px;
        .nav-link {
          height: 52px;
          padding: 9px 3px 0 3px;
          display: block;
          border-bottom: 3px solid $gray-300;
          transition: color .2s linear, border-bottom-color .2s linear;
          &:hover,
          &:focus {
            border-color: $primary;
          }
          svg {
            display: none;
          }
        }
        .router-link-active {
          border-color: $primary;
        }
      }

      @include media-breakpoint-up(xl) {
        padding-right: 26px;
        padding-left: 26px;
        &::after {
          content: "";
          display: block;
          background: $gray-300;
          height: 38px;
          width: 2px;
          position: absolute;
          top: 0;
          right: -1px;
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }

    .individual-width > a {
      width: 16rem;
      @include media-breakpoint-up(lg) {
        width: 13rem;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
      margin: 38px -3px 0 0;
    }
  }
</style>

<i18n src="@/translations/navigation.i18n.yaml" lang="yaml"/>
