import Http from '@/services/http';

if (Http.isMockingEnabled()) {
  const mock = Http.mockAdapter;

  let companyData = {
    'id': '0',
    'firstName': 'Max',
    'lastName': 'Musterfirma',
    'email': 'max.mustermann@example.com',
    'type': 'company',
  };
  let applicantData = {
    'id': '0',
    'firstName': 'Max',
    'lastName': 'Musterbewerber',
    'email': 'max.mustermann@example.com',
    'type': 'applicant',
    'accountMaxLifetime': 150,
    'accountLifetime': 50,
    'allowRevalidation': true,
  };

  mock.onGet('/account').reply(function (data) {
    if (data.headers.Authorization === `Bearer applicant`) {
      return [200, applicantData];
    } else if (data.headers.Authorization === `Bearer company`) {
      return [200, companyData];
    } else {
      return [403, null];
    }
  });

  mock.onGet('/account/dashboard').reply(function (data) {
    if (data.headers.Authorization === `Bearer applicant`) {
      return [200, {
        'profile': {
          'filledPercentage': 75,
          'visible': true,
        },
        'messages': {
          'readMessages': 3,
          'unreadMessages': 5,
        },
        'account': {},
        'companySearchProfiles': {
          'profiles': [
            'string',
            'string',
          ],
        },
        'companyBookmarks': {
          'positive': 10,
          'neutral': 2,
          'negative': 3,
        },
      }];
    } else if (data.headers.Authorization === `Bearer company`) {
      return [200, {
        'profile': {
          'filledPercentage': 75,
          'visible': false,
        },
        'messages': {
          'readMessages': 3,
          'unreadMessages': 5,
        },
        'account': {},
        'applicantSearchProfiles': {
          'profiles': [
            'string',
          ],
        },
        'applicantBookmarks': {
          'positive': 10,
          'neutral': 2,
          'negative': 3,
        },
      }];
    } else {
      return [403, null];
    }
  });

  mock.onPost('/account').reply(function (data) {
    if (data.headers.Authorization === `Bearer applicant`) {
      applicantData = Object.assign({}, applicantData, JSON.parse(data.data));
      return [200, applicantData];
    } else if (data.headers.Authorization === `Bearer company`) {
      companyData = Object.assign({}, companyData, JSON.parse(data.data));
      return [200, companyData];
    } else {
      return [403, null];
    }
  });

  mock.onPut('/account').reply(function (data) {
    return [200, null];
  });

  mock.onPost('/account/request-password').reply(function (data) {
    return [200, null];
  });

  mock.onPost('/account/reset-password').reply(function (data) {
    return [200, null];
  });

  mock.onDelete('/account').reply(function (data) {
    return [200, {}];
  });

  // End Mocks
}
