import Http from '@/services/http';
import { fetchRecord, fetchRecordList } from '@/api/record';
import { RECORD_TYPE } from '@/api/constants';

export function getOwnApplicantProfile () {
  return Http.get('/applicant-profile');
}

export function setOwnApplicantProfile (profile) {
  return Http.post('/applicant-profile', profile);
}

export function getApplicantManagementList () {
  return fetchRecordList(RECORD_TYPE.APPLICANT_MANAGEMENT);
}
export function getApplicantManagementData (applicantId) {
  return fetchRecord(RECORD_TYPE.APPLICANT_MANAGEMENT, applicantId);
}
export function saveApplicantManagement (applicantId, data) {
  return Http.post(`/applicant-management/${applicantId}`, data);
}

export function renewOwnApplicantProfile () {
  return Http.get('/applicant-profile/renew');
}

export function setOwnApplicantProfileImage (image) {
  const data = new FormData();
  if (image) {
    data.append('image', image, image.name);
    return Http.postMultipart('/applicant-profile/image', data);
  }
  return Http.post('/applicant-profile/image', { image: null });
}

export function setOwnApplicantProfileAttachment (file, type) {
  const data = new FormData();
  if (file) {
    data.append('file', file, file.name);
  }
  return Http.postMultipart(`/applicant-profile/file/${type}`, data);
}

// ----------------------------------------------------------------------------

export function getApplicant (applicantId) {
  return fetchRecord(RECORD_TYPE.APPLICANT, applicantId);
}

export function getApplicantImage (applicantId) {
  return Http.getBinary(`/applicant/${applicantId}/image`);
}

export function getApplicantAttachment (applicantId, type, fileName) {
  return Http.download(`/applicant/${applicantId}/file/${type}`, fileName);
}

export function getApplicantSettings () {
  return Http.get('/applicant-profile/settings');
}
