'use strict';

const ACCOUNT_STATE = {
  lastUpdate: null,
  documents: {},
  searchDocuments: {},
  searchQualifications: [],
  searchQualificationCountries: [],
  searchDistances: [],
  searchGrades: [],
  searchNotificationIntervals: [],
  studyYears: [],
  subjectAreas: [],
  studyCourses: [],
  studyInterests: [],
  expertises: [],
  messagingSettings: {},
  applicantSettings: {},
  emailSubscriptions: {},
};

export default ACCOUNT_STATE;
