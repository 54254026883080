'use strict';

const SEARCH_STATE = {
  searchData: {
    applicant: null,
    applicant_bookmark: null,
    company: null,
    company_bookmark: null,
  },
};

export default SEARCH_STATE;
