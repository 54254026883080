var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar-nav",
    { staticClass: "main-nav" },
    [
      _vm.enableSearchForCompanies
        ? _c(
            "b-nav-item",
            { attrs: { to: { name: "companysearch" } } },
            [
              _c("font-awesome-icon", { attrs: { icon: "building" } }),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t(
                      "components.partials.header.ba_navigation.companysearch"
                    )
                  ) +
                  "\n  "
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAuthenticated
        ? _c(
            "b-nav-item",
            { attrs: { to: { name: "register" } } },
            [
              _c("font-awesome-icon", { attrs: { icon: "user" } }),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("components.partials.header.ba_navigation.register")
                  ) +
                  "\n  "
              ),
            ],
            1
          )
        : _vm.isApplicantAccount
        ? _c(
            "b-nav-item",
            { attrs: { to: { name: "profile" } } },
            [
              _c("font-awesome-icon", { attrs: { icon: "user" } }),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("components.partials.header.ba_navigation.profile")
                  ) +
                  "\n  "
              ),
            ],
            1
          )
        : _vm.isCompanyAccount
        ? _c(
            "b-nav-item",
            { attrs: { to: { name: "applicantsearch" } } },
            [
              _c("font-awesome-icon", { attrs: { icon: "user" } }),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t(
                      "components.partials.header.ba_navigation.applicantsearch"
                    )
                  ) +
                  "\n  "
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-nav-item",
        { attrs: { to: { name: "studypreparation" } } },
        [
          _c("font-awesome-icon", { attrs: { icon: "book" } }),
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t(
                  "components.partials.header.ba_navigation.studypreparation"
                )
              ) +
              "\n  "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.mathTestUrl
        ? _c(
            "b-nav-item",
            { attrs: { href: _vm.mathTestUrl } },
            [
              _c("font-awesome-icon", { attrs: { icon: "calculator" } }),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("components.partials.header.ba_navigation.mathx3")
                  ) +
                  "\n  "
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-nav-item",
        { attrs: { to: { name: "contact" } } },
        [
          _c("font-awesome-icon", { attrs: { icon: "envelope" } }),
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("components.partials.header.ba_navigation.contact")
              ) +
              "\n  "
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }