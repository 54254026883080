import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/../l10n/translations_deprecated.json';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages,
});

Vue.prototype.$legacyTranslation = i18n;

export default i18n;
