'use strict';

import state from './app.state';
import { remove } from 'lodash';

const actions = {
  closeNotification (context, notification) {
    context.commit('REMOVE_NOTIFICATION', notification.id);
  },
  showNotification (context, notification) {
    if (!notification.id) notification.id = Math.random().toString(36).substring(2);
    context.commit('ENQUEUE_NOTIFICATION', notification);
    setTimeout(function () {
      context.commit('REMOVE_NOTIFICATION', notification.id);
    }, 3000);
  },
};

const mutations = {
  ENQUEUE_NOTIFICATION (state, notification) {
    state.notifications.push(notification);
  },
  REMOVE_NOTIFICATION (state, notificationId) {
    state.notifications = remove(state.notifications, function (notification) {
      return notification.id !== notificationId;
    });
  },
};

const getters = {
  notifications: function (state) {
    return state.notifications;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
