var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-nav-dropdown",
    {
      attrs: {
        "menu-class": "menu dropdown-user-actions",
        right: "",
        variant: "text",
        "no-caret": true,
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c("div", { staticClass: "has-text-icon" }, [
                _vm.messageCount
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "icon-circle bg-info top-nav-has-new-messages",
                        attrs: {
                          title: _vm.$tc(
                            "user.menu.new_messages",
                            _vm.messageCount
                          ),
                        },
                      },
                      [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                      1
                    )
                  : _c(
                      "span",
                      { staticClass: "icon-circle bg-info" },
                      [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                      1
                    ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "user-menu-info" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.activeAccount.firstName) +
                        " " +
                        _vm._s(_vm.activeAccount.lastName) +
                        "\n        "
                    ),
                    _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: "chevron-down" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.isCompanyAccount
        ? _c("b-dropdown-item", { attrs: { disabled: true } }, [
            _c("span", { staticClass: "text-ellipsis" }, [
              _vm._v(_vm._s(_vm.activeAccount.displayName)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: { name: "dashboard" } } }, [
        _c(
          "span",
          { staticClass: "icon-wrap" },
          [_c("font-awesome-icon", { attrs: { icon: "tachometer-alt" } })],
          1
        ),
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("components.partials.header.nav.dashboard")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: { name: "messages" } } }, [
        _vm.messageCount
          ? _c(
              "span",
              {
                staticClass: "icon-wrap has-new-messages",
                attrs: {
                  title: _vm.$tc("user.menu.new_messages", _vm.messageCount),
                },
              },
              [_c("font-awesome-icon", { attrs: { icon: "envelope" } })],
              1
            )
          : _c(
              "span",
              { staticClass: "icon-wrap" },
              [_c("font-awesome-icon", { attrs: { icon: "envelope" } })],
              1
            ),
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("components.partials.header.nav.messages")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.isApplicantAccount
        ? _c("b-dropdown-item", { attrs: { to: { name: "profile" } } }, [
            _c(
              "span",
              { staticClass: "icon-wrap" },
              [_c("font-awesome-icon", { attrs: { icon: "user" } })],
              1
            ),
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("components.partials.header.nav.profile_applicant")
                ) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanyAccount &&
      _vm.isGranted(_vm.permissions.COMPANY_EDIT_PROFILE)
        ? _c(
            "b-dropdown-item",
            { attrs: { to: { name: "owncompanyprofile" } } },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("components.partials.header.nav.profile_company")
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.enableSearchForCompanies && _vm.isApplicantAccount
        ? _c(
            "b-dropdown-item",
            { attrs: { to: { name: "companybookmarks" } } },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "bookmark" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("components.partials.header.nav.bookmarks_applicant")
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm.isCompanyAccount
        ? _c(
            "b-dropdown-item",
            { attrs: { to: { name: "applicantbookmarks" } } },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "bookmark" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("components.partials.header.nav.bookmarks_company")
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanyAccount
        ? _c(
            "b-dropdown-item",
            { attrs: { to: { name: "applicantsearchprofiles" } } },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "search" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("components.partials.header.nav.search_profiles")
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm.enableSearchForCompanies && _vm.isApplicantAccount
        ? _c(
            "b-dropdown-item",
            { attrs: { to: { name: "companysearchprofiles" } } },
            [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "search" } })],
                1
              ),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("components.partials.header.nav.search_profiles")
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: { name: "account" } } }, [
        _c(
          "span",
          { staticClass: "icon-wrap" },
          [_c("font-awesome-icon", { attrs: { icon: "address-card" } })],
          1
        ),
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("components.partials.header.nav.account")) +
            "\n  "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }