'use strict';

import defaultState from './staticdata.state';

import {
  getAvailableDocuments,
  getAvailableExpertises,
  getAvailableGrades,
  getAvailablePlaceRadius,
  getAvailableQualifications,
  getAvailableSearchDocuments,
  getAvailableSearchNotificationIntervals,
  getAvailableSearchQualificationCountries,
  getAvailableSubjectAreas,
  getAvailableStudyCourses,
  getAvailableStudyInterests,
  getAvailableStudyYears,
  getAvailableEmailSubscriptions,
} from '@/api/system';

import {
  getMessagingSettings,
} from '@/api/messaging';

import {
  getApplicantSettings,
} from '@/api/applicant';

const tasks = [
  {
    stateProperty: 'searchDocuments',
    mutator: 'UPDATE_SEARCH_DOCUMENTS',
    apiMethod: getAvailableSearchDocuments,
  },
  {
    stateProperty: 'searchQualifications',
    mutator: 'UPDATE_SEARCH_QUALIFICATIONS',
    apiMethod: getAvailableQualifications,
  },
  {
    stateProperty: 'searchDistances',
    mutator: 'UPDATE_SEARCH_DISTANCES',
    apiMethod: getAvailablePlaceRadius,
  },
  {
    stateProperty: 'searchGrades',
    mutator: 'UPDATE_SEARCH_GRADES',
    apiMethod: getAvailableGrades,
  },
  {
    stateProperty: 'studyYears',
    mutator: 'UPDATE_STUDY_YEARS',
    apiMethod: getAvailableStudyYears,
  },
  {
    stateProperty: 'subjectAreas',
    mutator: 'UPDATE_SUBJECT_AREAS',
    apiMethod: getAvailableSubjectAreas,
  },
  {
    stateProperty: 'studyCourses',
    mutator: 'UPDATE_STUDY_COURSES',
    apiMethod: getAvailableStudyCourses,
  },
  {
    stateProperty: 'studyInterests',
    mutator: 'UPDATE_STUDY_INTERESTS',
    apiMethod: getAvailableStudyInterests,
  },
  {
    stateProperty: 'expertises',
    mutator: 'UPDATE_EXPERTISES',
    apiMethod: getAvailableExpertises,
  },
  {
    stateProperty: 'searchQualificationCountries',
    mutator: 'UPDATE_SEARCH_QUALIFICATION_COUNTRIES',
    apiMethod: getAvailableSearchQualificationCountries,
  },
  {
    stateProperty: 'searchNotificationIntervals',
    mutator: 'UPDATE_SEARCH_NOTIFICATION_INTERVALS',
    apiMethod: getAvailableSearchNotificationIntervals,
  },
  {
    stateProperty: 'documents',
    mutator: 'UPDATE_DOCUMENTS',
    apiMethod: getAvailableDocuments,
  },
  {
    stateProperty: 'emailSubscriptions',
    mutator: 'UPDATE_EMAIL_SUBSCRIPTIONS',
    apiMethod: getAvailableEmailSubscriptions,
  },
  {
    stateProperty: 'messagingSettings',
    mutator: 'UPDATE_MESSAGING_SETTINGS',
    apiMethod: getMessagingSettings,
  },
  {
    stateProperty: 'applicantSettings',
    mutator: 'UPDATE_APPLICANT_SETTINGS',
    apiMethod: getApplicantSettings,
  },
];

const actions = {
  updateStaticData ({ state, commit }) {
    const now = Math.round(Date.now() / 1000);
    const dataOutdated = state.lastUpdate === undefined || state.lastUpdate < (now - 3600);
    if (dataOutdated) {
      commit('UPDATE_LAST_UPDATE', now);

      const promises = [];

      for (let i = 0; i < tasks.length; i++) {
        const { stateProperty, mutator, apiMethod } = tasks[i];

        if (state[stateProperty] !== undefined) {
          const promise = apiMethod()
            .then(({ data }) => {
              commit(mutator, data);
            })
          ;
          promises.push(promise);
        }
      }

      return Promise.all(promises);
    }
  },
  forceUpdateStaticData ({ dispatch, commit }) {
    commit('UPDATE_LAST_UPDATE', 0);

    return dispatch('updateStaticData');
  },
};

const mutations = {
  UPDATE_LAST_UPDATE (state, data) {
    state.lastUpdate = (undefined === data) ? defaultState.lastUpdate : data;
  },
  UPDATE_SEARCH_DOCUMENTS (state, data) {
    state.searchDocuments = (undefined === data) ? defaultState.searchDocuments : data;
  },
  UPDATE_SEARCH_QUALIFICATIONS (state, data) {
    state.searchQualifications = (undefined === data) ? defaultState.searchQualifications : data;
  },
  UPDATE_SEARCH_DISTANCES (state, data) {
    state.searchDistances = (undefined === data) ? defaultState.searchDistances : data;
  },
  UPDATE_SEARCH_GRADES (state, data) {
    state.searchGrades = (undefined === data) ? defaultState.searchGrades : data;
  },
  UPDATE_STUDY_YEARS (state, data) {
    state.studyYears = (undefined === data) ? defaultState.studyYears : data;
  },
  UPDATE_SUBJECT_AREAS (state, data) {
    state.subjectAreas = (undefined === data) ? defaultState.subjectAreas : data;
  },
  UPDATE_STUDY_COURSES (state, data) {
    state.studyCourses = (undefined === data) ? defaultState.studyCourses : data;
  },
  UPDATE_STUDY_INTERESTS (state, data) {
    state.studyInterests = (undefined === data) ? defaultState.studyInterests : data;
  },
  UPDATE_EXPERTISES (state, data) {
    state.expertises = (undefined === data) ? defaultState.expertises : data;
  },
  UPDATE_SEARCH_QUALIFICATION_COUNTRIES (state, data) {
    state.searchQualificationCountries = (undefined === data) ? defaultState.searchQualificationCountries : data;
  },
  UPDATE_SEARCH_NOTIFICATION_INTERVALS (state, data) {
    state.searchNotificationIntervals = (undefined === data) ? defaultState.searchNotificationIntervals : data;
  },
  UPDATE_DOCUMENTS (state, data) {
    state.documents = (undefined === data) ? defaultState.documents : data;
  },
  UPDATE_EMAIL_SUBSCRIPTIONS (state, data) {
    state.emailSubscriptions = (undefined === data) ? defaultState.emailSubscriptions : data;
  },
  UPDATE_MESSAGING_SETTINGS (state, data) {
    state.messagingSettings = (undefined === data) ? defaultState.messagingSettings : data;
  },
  UPDATE_APPLICANT_SETTINGS (state, data) {
    state.applicantSettings = (undefined === data) ? defaultState.applicantSettings : data;
  },
};

const getters = {
  availableSearchDocuments: function (state) {
    return Object.assign({}, state.searchDocuments ? state.searchDocuments : defaultState.searchDocuments);
  },
  availableQualifications: function (state) {
    return state.searchQualifications ? state.searchQualifications : defaultState.searchQualifications;
  },
  availableDistances: function (state) {
    return state.searchDistances ? state.searchDistances : defaultState.searchDistances;
  },
  availableGrades: function (state) {
    return state.searchGrades ? state.searchGrades : defaultState.searchGrades;
  },
  availableStudyYears: function (state) {
    return state.studyYears ? state.studyYears : defaultState.studyYears;
  },
  availableSubjectAreas: function (state) {
    return state.subjectAreas ? state.subjectAreas : defaultState.subjectAreas;
  },
  availableStudyCourses: function (state) {
    return state.studyCourses ? state.studyCourses : defaultState.studyCourses;
  },
  availableStudyInterests: function (state) {
    return state.studyInterests ? state.studyInterests : defaultState.studyInterests;
  },
  availableExpertises: function (state) {
    return state.expertises ? state.expertises : defaultState.expertises;
  },
  availableSearchQualificationCountries: function (state) {
    return state.searchQualificationCountries ? state.searchQualificationCountries : defaultState.searchQualificationCountries;
  },
  availableSearchNotificationIntervals: function (state) {
    return state.searchNotificationIntervals ? state.searchNotificationIntervals : defaultState.searchNotificationIntervals;
  },
  availableDocuments: function (state) {
    return Object.assign({}, state.documents ? state.documents : defaultState.documents);
  },
  availableEmailSubscriptions: function (state) {
    return Object.assign({}, state.emailSubscriptions ? state.emailSubscriptions : defaultState.emailSubscriptions);
  },
  messagingSettings: function (state) {
    return Object.assign({}, state.messagingSettings ? state.messagingSettings : defaultState.messagingSettings);
  },
  applicantSettings: function (state) {
    return Object.assign({}, state.applicantSettings ? state.applicantSettings : defaultState.applicantSettings);
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
