<template>
  <div class="user-menu">
    <div class="container nav-container">
      <div class="user-info">
        <div class="user-icon">
          <font-awesome-icon size="2x" icon="building"/>
        </div>
        <div class="user-data">
          <h5>{{ activeAccount.firstName }} {{ activeAccount.lastName }}</h5>
          <div v-if="isCompanyAccount">{{ activeAccount.displayName }}</div>
          <b-link @click="logout()">{{ $t('components.partials.header.nav_mobile.action_logout') }}</b-link>
        </div>
      </div>
      <b-navbar-nav class="user-links">
        <b-nav-item :to="{ name: 'dashboard' }">
          <font-awesome-icon icon="tachometer-alt"/>
          {{ $t('components.partials.header.nav_mobile.dashboard') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'messages' }">
          <span class="icon-wrap has-new-messages" :title="$tc('user.menu.new_messages', messageCount)" v-if="messageCount">
          <font-awesome-icon icon="envelope"/>
          </span>
          <span class="icon-wrap" v-else>
            <font-awesome-icon icon="envelope"/>
          </span>
          {{ $t('components.partials.header.nav_mobile.messages') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'profile' }" v-if="isApplicantAccount">
          <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
          <span>{{ $t('components.partials.header.nav_mobile.profile_applicant') }}</span>
        </b-nav-item>
        <b-nav-item :to="{ name: 'owncompanyprofile' }" v-if="isCompanyAccount && isGranted(permissions.COMPANY_EDIT_PROFILE)">
          <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
          <span>{{ $t('components.partials.header.nav_mobile.profile_company') }}</span>
        </b-nav-item>
        <b-nav-item :to="{ name: 'companybookmarks' }" v-if="enableSearchForCompanies && isApplicantAccount">
          <span class="icon-wrap"><font-awesome-icon icon="bookmark"/></span>
          {{ $t('components.partials.header.nav_mobile.bookmarks_applicant') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'applicantbookmarks' }" v-else-if="isCompanyAccount">
          <span class="icon-wrap"><font-awesome-icon icon="bookmark"/></span>
          {{ $t('components.partials.header.nav_mobile.bookmarks_company') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'applicantsearchprofiles' }" v-if="isCompanyAccount">
          <span class="icon-wrap"><font-awesome-icon icon="search"/></span>
          {{ $t('components.partials.header.nav_mobile.search_profiles') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'companysearchprofiles' }" v-else-if="enableSearchForCompanies && isApplicantAccount">
          <span class="icon-wrap"><font-awesome-icon icon="search"/></span>
          {{ $t('components.partials.header.nav_mobile.search_profiles') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'account' }">
          <span class="icon-wrap"><font-awesome-icon icon="address-card"/></span>
          {{ $t('components.partials.header.nav_mobile.account') }}
        </b-nav-item>
      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import ShowMessageCountMixin from '@/mixins/showMessageCountMixin';
  import permissions from '@/services/permissions';
  import Config from '@/services/config';

  export default {
    mixins: [ShowMessageCountMixin],
    props: {
      recordId: Number,
    },
    computed: {
      ...mapGetters(['activeAccount', 'isCompanyAccount', 'isApplicantAccount', 'isGranted']),
      permissions: () => permissions,
      enableSearchForCompanies: function () {
        return !!Config.enableSearchForCompanies;
      },
    },
    methods: {
      ...mapActions(['logout']),
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .user-menu {
    background-color: $gray-100;
    padding: 65px 0 5px 0;

    .user-info {
      font-size: 1rem;
      line-height: 1.5;
      display: flex;
      @media (min-width: 380px) {
        // reset padding set by nav-container
        margin-left: -21px;
      }
      h5 {
        font-size: 1rem;
        font-weight: $ba-font-weight-medium;
        line-height: 1.5;
        margin: 0;
      }
      .user-icon {
        flex: 0 0 75px;
        padding-top: 1.5rem;
        text-align: center;
        color: white;
        border-radius: 1rem;
        height: 75px;
        width: 75px;
        background-color: $ba-stormy-blue;
      }

      .user-data {
        margin-left: 1rem;
        margin-top: 9px;
      }
    }

    .user-links {
      display: block;
      font-weight: $ba-font-weight-medium;
      letter-spacing: .9px;
      margin: 1.5rem 0 0 0;
      width: 100%;
      text-transform: uppercase;
    }
    svg {
      display: inline-block;
      margin-right: 14px;
    }
    .icon-wrap {
      display: inline-block;
      width: 26px;
      svg {
        margin-right: 0;
      }
    }
    .has-new-messages {
      @include ba-circle-bg($ba-red, 6px, 1px, 10px);
    }

    @include media-breakpoint-up(lg) {
      display: none;
      visibility: hidden;
    }
  }
</style>

<i18n src="@/mixins/showMessageCountMixin.i18n.yaml" lang="yaml"/>

<i18n lang="yaml" locale="de">
  components.partials.header.nav_mobile.action_logout: 'Logout'
  components.partials.header.nav_mobile.dashboard: 'Dashboard'
  components.partials.header.nav_mobile.messages: 'Nachrichten'
  components.partials.header.nav_mobile.bookmarks_company: 'Bewerber*innen'
  components.partials.header.nav_mobile.bookmarks_applicant: 'Merkliste'
  components.partials.header.nav_mobile.search_profiles: 'Suchaufträge'
  components.partials.header.nav_mobile.profile_company: 'Profil'
  components.partials.header.nav_mobile.profile_applicant: 'Bewerbungsprofil'
  components.partials.header.nav_mobile.account: 'Account'
</i18n>
