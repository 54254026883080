import Http from '@/services/http';

if (Http.isMockingEnabled()) {
  const mock = Http.mockAdapter;

  let getApplicantTeaser = function (id) {
    return {
      'id': id,
      'firstName': 'Max',
      'lastName': 'Mustermann',
      'birthdate': 'Jan 31 1970',
      'image': (Math.random() > 0.5),
      'studyInterests': [
        'Digitale Medien',
      ],
      'places': [
        {
          'place': 'Mannheim',
          'radius': 0,
        },
        {
          'place': 'Berlin',
          'radius': 100,
        },
      ],
      'grade': '1.0',
      'desiredYears': [
        2021,
      ],
    };
  };

  mock.onGet('/applicants').reply(function (data) {
    return [200, [
      getApplicantTeaser(0),
    ]];
  });

  mock.onGet(/\/applicant\/bookmark\/[\d]+/).reply(function (data) {
    return [200, [
      getApplicantTeaser(0),
      getApplicantTeaser(2),
      getApplicantTeaser(3),
    ]];
  });

  mock.onGet('/applicant-profile/renew').reply(function (data) {
    return [200, {
      'id': '0',
      'firstName': 'Max',
      'lastName': 'Musterbewerber',
      'email': 'max.mustermann@example.com',
      'type': 'applicant',
      'accountMaxLifetime': 300,
      'accountLifetime': 50,
      'allowRevalidation': false,
    }];
  });

  mock.onPost('/applicant-profile').reply(function (request) {
    return [200, request.data];
  });

  mock.onGet('/applicant-search/pattern').reply(function (request) {
    return [200, [
      {
        'id': '1',
        'name': 'Meine Bewerber',
      },
    ]];
  });

  mock.onGet('/applicant-bookmarks').reply(function (request) {
    return [200, [
      {
        'id': '1',
        'name': 'Positiv',
      },
      {
        'id': '3',
        'name': 'Neutral',
      },
      {
        'id': '7',
        'name': 'Negativ',
      },
    ]];
  });

  mock.onGet(/\/applicant\/search\/pattern\/[\d]+/).reply(function (request) {
    const searchProfileId = parseInt(request.url.substr(request.url.lastIndexOf('/') + 1));
    return [200,
      {
        'id': searchProfileId,
        'name': 'Meine Bewerber',
        'studyCourses': [
          'Digitale Medien',
        ],
        'desiredStudyYears': [
          2019, 2020,
        ],
        'qualifications': [
          'Allgemeine Hochschulreife',
        ],
        'requiredDocuments': {
          application: 'Anschreiben',
          cv: 'Lebenslauf',
          certificate: 'Zeugnis',
          file1: 'Sonstiges 1',
          file2: 'Sonstiges 2',
        },
        'place':
          {
            'place': 'Mannheim',
            'radius': 100,
          },
        'grade': 1.3,
        'notificationInterval': 'Alle 7 Tage',
      },
    ];
  });

  mock.onGet(/\/applicant\/[\d]+/).reply(function (request) {
    const applicantId = parseInt(request.url.substr(request.url.lastIndexOf('/') + 1));
    return [200, {
      'id': applicantId,
      'gender': 'male',
      'firstName': 'Max',
      'lastName': 'Mustermann',
      'birthdate': 'Jan 31 1970',
      'image': (Math.random() > 0.5),
      'studyInterests': [
        'Digitale Medien',
      ],
      'places': [
        {
          'place': 'string',
          'radius': 0,
        },
      ],
      'grade': '1.0',
      'desiredYears': [
        2021,
      ],
      'expertises': [
        {
          'name': 'Zielorientierung',
          'grade': Math.round(Math.random() * 5) + 1,
          'description': 'Lorem ipsum dolor sit amet',
        },
        {
          'description': 'Lorem ipsum dolor sit amet',
          'name': 'Toleranz',
          'grade': Math.round(Math.random() * 5) + 1,
        },
      ],
      'languages': [
        {
          'name': 'Deutsch',
          'rating': Math.round(Math.random() * 100),
        },
        {
          'name': 'Englisch',
          'rating': Math.round(Math.random() * 100),
        },
        {
          'name': 'Französich',
          'rating': Math.round(Math.random() * 100),
        },
        {
          'name': 'Klingonisch',
          'rating': Math.round(Math.random() * 100),
        },
      ],
      'education': [
        {
          'academyName': 'Universität Mannheim',
          'academicTitle': 'Allgemeine Hochschulreife',
          'from': 'Jan 31 1970',
          'to': 'Jan 31 1970',
        },
        {
          'academyName': 'Universität MannheimY',
          'academicTitle': 'Allgemeine Hochschulreife',
          'from': 'Jan 31 1970',
          'to': 'Jan 31 1970',
        },
      ],
      'career': [
        {
          'companyName': 'Brain Appeal GmbH 1',
          'position': 'Software Developer',
          'from': 'Jan 31 1970',
          'to': 'Jan 31 1970',
        },
        {
          'companyName': 'Brain Appeal GmbH 2',
          'position': 'Software Developer',
          'from': 'Jan 31 1970',
          'to': 'Jan 31 1970',
        },
        {
          'companyName': 'Brain Appeal GmbH 3',
          'position': 'Software Developer',
          'from': 'Jan 31 1970',
          'to': 'Jan 31 1970',
        },
      ],
      'attachments': [
        {
          'key': 'cv',
          'name': 'Lebenslauf',
          'type': 'zip',
          'url': 'https://example.com/certificate.pdf',
        },
        {
          'key': 'doc1',
          'name': 'CV',
          'type': 'zip',
          'url': 'https://example.com/certificate.pdf',
        },
      ],
      'moduleAdditionals': {
        'mathx3': [
          {
            'name': 'Level 1',
            'rating': Math.round(Math.random() * 100),
            'visible': (Math.random() > 0.5),
          },
          {
            'name': 'Level 2',
            'rating': Math.round(Math.random() * 100),
            'visible': (Math.random() > 0.5),
          },
          {
            'name': 'Level 3',
            'rating': Math.round(Math.random() * 100),
            'visible': (Math.random() > 0.5),
          },
        ],
      },
      'aboutMe': 'Lorem ipsum dolor sit amet',
    },
    ];
  });

  // End Mocks
}
