<template>
  <b-input-group class="ba-group-password">
    <b-form-input v-bind="inputProperties" :type="inputType" :value="this.value" @input="handleInput"/>
    <b-input-group-append @click="toggle">
        <font-awesome-icon color="#b0b0b0" :icon="hidden ? 'eye' : 'eye-slash'"></font-awesome-icon>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
  export default {
    props: {
      value: null,
      id: null,
      placeholder: null,
      required: Boolean,
    },
    data: () => ({
      hidden: true,
    }),
    methods: {
      handleInput (event) {
        this.$emit('input', event);
      },
      toggle: function () {
        this.hidden = !this.hidden;
      },
    },
    computed: {
      inputType: function () {
        return this.hidden ? 'password' : 'text';
      },
      inputProperties: function () {
        let props = {
          id: this.id,
          placeholder: this.placeholder,
        };
        if (this.required) {
          props.required = this.required;
        }

        return props;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .ba-group-password {
    position: relative;
    .input-group-append {
      cursor: pointer;
      border: 1px solid $input-border-color;
      border-left-width: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65px;
    }
  }
  .has-error {
    .ba-group-password {
      .input-group-append {
        border-color: $ba-red;
        color: $ba-red;
      }
    }
  }
</style>
