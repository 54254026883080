'use strict';

import CustomConfig from '@/config.json';

const DefaultConfig = {
  projectName: 'campusMatch',
  projectLogo: '/studyUp.png',
  customer: {
    name: 'Brain Appeal',
    email: 'info@brain-appeal.com',
    phone: '+49 621 437843-00',
    logo: '/Customer-Logo.svg',
    logoFooter: '/Customer-Logo.svg',
    website: 'https://www.brain-appeal.com/',
    address: {
      street: 'Wildbader Straße 9',
      zipcode: '68239',
      town: 'Mannheim',
    },
  },
  logoLink: 'https://www.brain-appeal.com',
  // Beware: object sub.properties must be set in config.json with all properties!
  socialLinks: {
    facebook: 'https://www.facebook.com/brainappealgmbh/',
    xing: 'https://www.xing.com/companies/brainappealgmbh',
  },
  staticPages: {
    imprint: true,
    privacy: true,
    terms: true,
    accessibility: false,
    contact: false,
  },
  applicant: {
    enableMatches: false,
    studyYearUpdatedOn: '20. Oktober',
    studyInterestSearchCheckboxes: false,
    dashboardShowUserTip: true,
  },
  company: {
    // toggle visibility for company study offers; either 'all' or 'authenticated'
    studyOffersVisibility: 'all',
    studyInterestSearchCheckboxes: false,
    map: {
      enable: false,
      fitBounds: true,
      zoom: 6,
    },
    profileRedirectToUrlIfNotAuthenticated: null,
    dashboardShowUserTip: true,
    signupInfoEmail: 'info@brain-appeal.com',
    signupInfoUrl: '',
  },
  contactMap: {
    enable: true,
    zoom: 16,
    center: {
      latitude: 49.475054,
      longitude: 8.526493,
    },
    marker: {
      latitude: 49.4742106,
      longitude: 8.5327587,
    },
  },
  contactBackground: null,
  mathTestUrl: null,
  enableRegistrationVerification: true,
  enableRegistrationFormHash: true,
  enableSearchForCompanies: true,
};
const Config = Object.assign({}, DefaultConfig, CustomConfig);
// Merge customer and company properties, so we don't have to override the whole configuration for these sections
if (CustomConfig.customer) {
  Config.customer = Object.assign({}, DefaultConfig.customer, CustomConfig.customer);
}
if (CustomConfig.company) {
  Config.company = Object.assign({}, DefaultConfig.company, CustomConfig.company);
}

export default Config;
